import { ShopSettings } from '../types'
import GetShopSettings from './graphql/GetShopSettings.graphql'
import useQueryExt from './useQueryWrapper'

export function useGetShopSettings(
  setShopSettings?: (data: ShopSettings) => void,
  settingsId?: string,
) {
  const { data, loading, error } = useQueryExt(
    GetShopSettings,
    {
      fetchPolicy: 'cache-and-network', // did this because on default fetch policy component will not get refreshed child link click.
      variables: { input: { settingsId } },
      onCompleted: (data: any) => {
        setShopSettings && setShopSettings(data?.getShopSettings)
      },
    },
    'getShopSettings',
  )
  return { data, loading, error }
}

export default useGetShopSettings
