import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { RouteProps } from 'react-router'
import { isLogin } from '../utils/auth'

export const PrivateRoute: React.FC<RouteProps> = (props: RouteProps) => {
  const loggedIn = isLogin()

  if (!loggedIn) {
    return <Redirect to={{ pathname: '/login' }} />
  }

  return <Route {...props} />
}
