import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { IconButton as MuiIconButton } from '@material-ui/core'
import styled from 'styled-components'
import { useApolloClient } from '@apollo/client'
import { darken } from 'polished'
import { signOut } from '../../../../hooks/utils'
import GoogleAnalytics from '../../../../lib/GoogleAnalytics'
import logoutIcon from '../../../../assets/icons/logout.svg'
import logoutActiveIcon from '../../../../assets/icons/logout_active.svg'

const IconButton = styled(MuiIconButton)`
  width: 40px;
  height: 36px;
  background-color: ${props => props.theme.sidebar.background};
  border-radius: 4px;
  img.active {
    display: none;
  }
  &:hover {
    background-color: ${props => darken(0.06, props.theme.sidebar.background)};
    img.inactive {
      display: none;
    }
    img.active {
      display: inline;
    }
  }
`

type UserMenuProps = {}

const Logout: React.FC<UserMenuProps> = () => {
  const [loggedIn, setLoggedIn] = useState<boolean>(true)
  const client = useApolloClient()

  const out = (): void => {
    GoogleAnalytics.track({
      category: 'Authentication',
      action: 'Sign out',
    })
    signOut()
    setLoggedIn(false)
    ;(window as any).Intercom('boot', {
      // eslint-disable-next-line @typescript-eslint/camelcase
      app_id: 'g1bqh1bn',
    })
    client.resetStore()
  }

  if (!loggedIn) return <Redirect to={{ pathname: '/login' }} />

  return (
    <React.Fragment>
      <IconButton onClick={out} color="inherit">
        <img className="inactive" src={logoutIcon} alt="logout" />
        <img className="active" src={logoutActiveIcon} alt="logout active" />
      </IconButton>
    </React.Fragment>
  )
}

export default Logout
