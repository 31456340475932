import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { darken } from 'polished'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { spacing } from '@material-ui/system'
import {
  Chip,
  Collapse,
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography as MuiTypography,
  Grid,
} from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { Layers } from 'react-feather'
import cn from 'classnames'
import { sidebarRoutes as routes } from '../../../routes/index'
import { RouteInfoType, ChildElementType } from '../../../types'
import '../../../vendor/perfect-scrollbar.css'
import Logout from './Logout'
import NavLink from '../NavLink'
import useGetBrandSettings from '../../../hooks/useGetBrandSettings'

import { useHistory } from 'react-router-dom'
const Typography = styled(MuiTypography)(spacing)

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${props => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`

const List = styled(MuiList)`
  background-color: ${props => props.theme.sidebar.background};
`

const Items = styled.div`
  padding-top: ${props => props.theme.spacing(2.5)}px;
  padding-bottom: ${props => props.theme.spacing(2.5)}px;
`

const Brand = styled(ListItem)<{ button?: boolean }>`
  font-size: ${props => props.theme.typography.h5.fontSize};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.sidebar.header.color};
  background-color: ${props => props.theme.sidebar.background};
  font-family: ${props => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${props => props.theme.spacing(6)}px;
  padding-right: ${props => props.theme.spacing(6)}px;
  cursor: default;

  ${props => props.theme.breakpoints.up('sm')} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${props => props.theme.sidebar.header.background};
  }
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BrandIcon = styled(Layers)`
  margin-right: ${props => props.theme.spacing(2)}px;
  color: ${props => props.theme.sidebar.header.brand.color};
`
type CategoryType = {
  activeclassname?: string
  button?: boolean
  onClick?: () => void
  to?: string
  component?: typeof NavLink
  exact?: boolean
}

const Category = styled(ListItem)<CategoryType>`
  padding-top: ${props => props.theme.spacing(3)}px;
  padding-bottom: ${props => props.theme.spacing(3)}px;
  padding-left: ${props => props.theme.spacing(6)}px;
  padding-right: ${props => props.theme.spacing(5)}px;
  font-weight: ${props => props.theme.typography.fontWeightRegular};

  svg {
    color: ${props => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background-color: ${props => darken(0.06, props.theme.sidebar.background)};
  }

  img.menu-icon.active {
    display: none;
  }
  &.${props => props.activeclassname} {
    span {
      color: #10c095;
    }
    img.menu-icon.inactive {
      display: none;
    }
    img.menu-icon.active {
      display: inline;
    }
  }
  &[activeclassname='active'] {
    &.open {
      background-color: ${props =>
        darken(0.06, props.theme.sidebar.background)};

      + .MuiCollapse-container {
        background-color: ${props =>
          darken(0.06, props.theme.sidebar.background)};
      }
    }
  }
`

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${props => props.theme.sidebar.color};
    font-size: ${props => props.theme.typography.body1.fontSize}px;
    font-weight: ${props => props.theme.sidebar.category.fontWeight};
    padding: 0 ${props => props.theme.spacing(4)}px;
  }
`

const CategoryIconLess = styled(ExpandLess)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`

const CategoryIconMore = styled(ExpandMore)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`

export const Link = styled(ListItem)<{
  activeclassname: string
  component: typeof NavLink
  exact: boolean
  to: string | object
}>`
  padding-left: ${props => props.theme.spacing(15)}px;
  padding-top: ${props => props.theme.spacing(2)}px;
  padding-bottom: ${props => props.theme.spacing(2)}px;

  span {
    color: ${props => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${props => rgba(props.theme.sidebar.color, 0.9)};
  }

  &.${props => props.activeclassname} {
    background-color: ${props => darken(0.06, props.theme.sidebar.background)};

    span {
      color: #10c095;
    }
  }
`

export const LinkText = styled(ListItemText)`
  color: ${props => props.theme.sidebar.color};
  span {
    font-size: ${props => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`

export const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${props => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  background: ${props => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${props => props.theme.sidebar.badge.color};
    padding-left: ${props => props.theme.spacing(2)}px;
    padding-right: ${props => props.theme.spacing(2)}px;
  }
`

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`

const SidebarSection = styled(Typography)`
  color: ${props => props.theme.sidebar.color};
  padding: ${props => props.theme.spacing(4)}px
    ${props => props.theme.spacing(6)}px ${props => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`

const SidebarFooter = styled.div`
  background-color: ${props =>
    props.theme.sidebar.footer.background} !important;
  padding: ${props => props.theme.spacing(2.75)}px
    ${props => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`

const SidebarFooterText = styled(Typography)`
  color: ${props => props.theme.sidebar.footer.color};
  font-size: 16px;
`

type SidebarCategoryPropsType = {
  name: string
  icon: JSX.Element
  classes?: string
  isOpen?: boolean
  isCollapsable: boolean
  badge?: string | number
  activeclassname?: string
  button: true
  onClick?: () => void
  to?: string
  component?: typeof NavLink
  exact?: boolean
  disabled?: boolean
}

const SidebarCategory: React.FC<SidebarCategoryPropsType> = ({
  name,
  icon,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  classes,
  isOpen,
  isCollapsable,
  badge,
  ...rest
}: SidebarCategoryPropsType) => {
  return (
    <Category className={cn({ open: !isOpen })} {...rest}>
      {icon}
      <CategoryText>{name}</CategoryText>
      {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore />
        ) : (
          <CategoryIconLess />
        )
      ) : null}
      {badge ? <CategoryBadge label={badge} /> : ''}
    </Category>
  )
}

type SidebarLinkPropsType = {
  name: string
  to: string
  badge?: string | number
  icon?: JSX.Element
}

const SidebarLink: React.FC<SidebarLinkPropsType> = ({
  name,
  to,
  badge,
}: SidebarLinkPropsType) => {
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeclassname="active"
    >
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ''}
    </Link>
  )
}

type SidebarPropsType = {
  classes?: string
  staticContext: string
  location: {
    pathname: string
  }
  routes: Array<RouteInfoType>
  PaperProps: {
    style: {
      width: number
    }
  }
  variant?: 'permanent' | 'persistent' | 'temporary'
  open?: boolean
  onClose?: () => void
}

const Sidebar: React.FC<RouteComponentProps & SidebarPropsType> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  classes,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  staticContext,
  location,
  match,
  ...rest
}: RouteComponentProps & SidebarPropsType) => {
  const history = useHistory()
  const { data } = useGetBrandSettings()

  const initOpenRoutes = async (routes: Array<RouteInfoType>): Promise<any> => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname

    let _routes = {}

    for (const route of routes) {
      if (route.hasDynamicChilds && route.childRoutesCb) {
        route.children = await route?.childRoutesCb()
      }
    }

    routes.forEach((route: RouteInfoType, index: number) => {
      const isActive = pathName.indexOf(route.path) === 0
      const isOpen = route.open
      const isHome = route.containsHome && pathName === '/'

      _routes = Object.assign({}, _routes, {
        [index]: isActive || isOpen || isHome,
      })
    })

    return _routes
  }
  const [openRoutes, setOpenRoutes] = useState(() => [])
  useEffect(() => {
    if (routes) {
      ;(async () => {
        const Routes = await initOpenRoutes(routes)
        setOpenRoutes(() => Routes)
      })()
    }
    if (data) {
      if (
        !data?.getBrandSettings?.isShopSetup &&
        data?.getBrandSettings?.myShopReferences?.length > 0 &&
        data?.getBrandSettings?.status !== 'onlyMyShopPending'
      ) {
        const params = match?.params
        if (!match.path.includes('setup')) {
          history.push(`/setup/1`)
        } else {
          const { step, refresh } = params as any
          if (step && refresh) {
            history.push(`/setup/${step}`)
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes, data])

  const toggle = (index: number) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      item =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes: any) =>
          Object.assign({}, openRoutes, { [item]: false }),
        ),
    )

    // Toggle selected element
    setOpenRoutes((openRoutes: any) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] }),
    )
  }

  const name = data?.getBrandSettings?.name

  return (
    <div>
      <Drawer variant="permanent" {...rest}>
        <Brand button>
          <img src="/images/logo-main.png" alt="myshop" width="128" />
        </Brand>
        <Scrollbar>
          <List disablePadding>
            <Items>
              {routes.map((category: RouteInfoType, index: number) => (
                <React.Fragment key={index}>
                  {category.header ? (
                    <SidebarSection>{category.header}</SidebarSection>
                  ) : null}

                  {category.children && category.icon ? (
                    <React.Fragment key={index}>
                      <SidebarCategory
                        disabled={!data?.getBrandSettings?.isShopSetup}
                        activeclassname={
                          data?.getBrandSettings?.isShopSetup ? 'active' : ''
                        }
                        isOpen={!openRoutes[index]}
                        isCollapsable={true}
                        name={category.id}
                        icon={category.icon}
                        button={true}
                        onClick={() => toggle(index)}
                      />

                      <Collapse
                        in={openRoutes[index]}
                        timeout="auto"
                        unmountOnExit
                      >
                        {!category.hasDynamicChilds
                          ? category.children.map(
                              (route: ChildElementType, index: number) => {
                                return (
                                  <SidebarLink
                                    key={index}
                                    name={route.name}
                                    to={route.path}
                                    icon={route.icon}
                                    badge={route.badge}
                                  />
                                )
                              },
                            )
                          : category.children.map(
                              (route: ChildElementType, index: number) => (
                                <route.component
                                  key={index}
                                  name={route.name}
                                  to={route.path}
                                  icon={category.icon}
                                  badge={route.badge}
                                  settingsId={route.key}
                                />
                              ),
                            )}
                      </Collapse>
                    </React.Fragment>
                  ) : category.icon ? (
                    <SidebarCategory
                      isCollapsable={false}
                      name={category.id}
                      to={category.path}
                      activeclassname={
                        data?.getBrandSettings?.isShopSetup ? 'active' : ''
                      }
                      component={NavLink}
                      icon={category.icon}
                      disabled={!data?.getBrandSettings?.isShopSetup}
                      exact
                      button
                      badge={category.badge}
                      isOpen
                    />
                  ) : null}
                </React.Fragment>
              ))}
            </Items>
          </List>
        </Scrollbar>
        <SidebarFooter>
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs>
              <SidebarFooterText variant="body2">{name}</SidebarFooterText>
            </Grid>
            <Grid item>
              <Logout />
            </Grid>
          </Grid>
        </SidebarFooter>
      </Drawer>
    </div>
  )
}
export default withRouter(Sidebar)
