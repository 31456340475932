import { Button, Grid, IconButton, Typography } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { useState } from 'react'
import referalLinkIcon from '../../assets/icons/referalLink.svg'
import useGetBrandSettings from '../../hooks/useGetBrandSettings'
import { useHistory } from 'react-router-dom'

type Props = {}

const useStyles = makeStyles(() => ({
  box: {
    padding: '24px 40px',
    background: '#D6F0ED',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
}))

const ReferalLinksBox: React.FC<Props> = () => {
  const classes = useStyles()
  const { loading, data } = useGetBrandSettings()
  const [showReferralBox, setShowReferralBox] = useState(true)
  const history = useHistory()
  if (loading) return null

  const haveCommissionBalance = data?.getBrandSettings?.commissionsBalance

  return (
    <>
      {!haveCommissionBalance &&
      data?.getBrandSettings?.isShopSetup &&
      showReferralBox ? (
        <div className={classes.box}>
          <Grid container wrap="nowrap">
            <Grid
              item
              container
              wrap="nowrap"
              spacing={3}
              style={{ flexGrow: 1 }}
            >
              <Grid item>
                <img src={referalLinkIcon} alt="referal" />
              </Grid>
              <Grid item>
                <Typography variant="h6" style={{ marginBottom: 16 }}>
                  Sell more with Referal links to your webstore!
                </Typography>
                <Typography variant="body1">
                  We have now affiliate links to your webstore. Since the
                  shopping takes place on your end, you need to update your
                  Commission Account. The balance covers the commissions from
                  the affiliate link sales.
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justify="flex-end"
              style={{ width: 'auto' }}
            >
              <Button
                variant="contained"
                style={{ background: '#10C095', color: 'white', width: 240 }}
                onClick={() => history.push('/withdraw')}
              >
                Update Commission Account
              </Button>
            </Grid>
          </Grid>
          <IconButton
            onClick={() => setShowReferralBox(false)}
            className={classes.closeButton}
            aria-label="close"
          >
            <CloseOutlined fontSize="small" />
          </IconButton>
        </div>
      ) : null}
    </>
  )
}

export default ReferalLinksBox
